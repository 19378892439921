import HTML from "../imgSkills/html.webp"
import CSS from "../imgSkills/css.webp"
import Figma from "../imgSkills/figma.webp"
import Sass from "../imgSkills/sass.webp"
import Scss from "../imgSkills/scss.webp"
import bootstrap from "../imgSkills/bootstrap.webp"
import materialUi from "../imgSkills/materialUi.webp"
import tailwind from "../imgSkills/tailwind.webp"
import js from "../imgSkills/js.webp"
import jQuery from "../imgSkills/jQuery.webp"
import typescript from "../imgSkills/typescript.webp"
import react from "../imgSkills/react.webp"
import router from "../imgSkills/react-router.webp"
import redux from "../imgSkills/redux.webp"
import nextjs from "../imgSkills/nextjs.webp"
import firebase from "../imgSkills/firebase.webp"
import gulp from '../imgSkills/gulp.webp'
import github from '../imgSkills/github.webp'
import git from '../imgSkills/git.webp'

export const skillView = [ HTML, CSS, Figma, Sass, Scss, bootstrap, materialUi, tailwind, js, jQuery, typescript, react, router, redux, nextjs, firebase, gulp, git, github ]

export const menuNav = [
    {
        linkName: 'Home',
        slug: 'Home'
    },
    {
        linkName: 'About Me',
        slug: 'About'
    },
    {
        linkName: 'Overview',
        slug: 'Overview'
    },
    {
        linkName: 'Projects',
        slug: 'Projects'
    },
    {
        linkName: 'Contact',
        slug: 'Contact'
    },
]

export const linkContact = [
    {
        linkUrl: 'https://www.linkedin.com/in/jasurbek-xaitov-1a8484268/',
        iconType: 'skill-icons:linkedin',
        iconColor: 'blue',
        nameContact: 'Linkedln'
    },
    {
        linkUrl: 'https://github.com/jasurkhaitov',
        iconType: 'fa:github-square',
        iconColor: 'white',
        nameContact: 'GitHub'
    },
    {
        linkUrl: 'https://t.me/jasurkhaitov',
        iconType: 'logos:telegram',
        iconColor: 'blue',
        nameContact: 'Telegram'
    },
    {
        linkUrl: 'mailto:xaitovjasur0@gmail.com!',
        iconType: 'logos:google-gmail',
        iconColor: 'blue',
        nameContact: 'Email'
    },
]

export const linkEdu = [
    {
        linkUrl: 'https://www.linkedin.com/in/jasurbek-xaitov-1a8484268/',
        iconType: 'skill-icons:linkedin',
        iconColor: 'blue',
        nameContact: 'Linkedln'
    },
    {
        linkUrl: 'https://github.com/jasurkhaitov',
        iconType: 'fa:github-square',
        iconColor: 'white',
        nameContact: 'GitHub'
    }
]

export const skills = ['HTML', 'CSS', 'Figma', 'Bootstrap', 'Material UI', 'jQuery', 'Sass', 'Tailwind Css', 'Gulp', 'JavaScript', 'React', 'React Router', 'Redux', 'Redux Toolkit', 'Git & GitHub', 'Firebase', 'Next.js']